///////////////////////////////////////////////////////////////////////////////////MODULES
import { CustomInput, FormGroup, Label, Button, Col, Row, InputGroup, Form, ButtonGroup } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Header = ({
  userInputs,
  searchFilter,
  onSearchFilterChange,
  search,
  setSearch,
  toggleSettingsModal,
  onResetFiltersClick,
}) => (
  <Form
    onSubmit={e => {
      e.preventDefault();
      setSearch(!search);
    }}
  >
    <Row>
      <Col md={6}>
        <FormGroup floating>
          <CustomInput
            type='select'
            placeholder='Sort By'
            name='sortBy'
            value={searchFilter.sortBy}
            onChange={onSearchFilterChange}
          >
            {c.inputsOptions.sortByOptions.map((o, i) => (
              <option key={i} value={o.value}>
                {o.label}
              </option>
            ))}
          </CustomInput>
          <Label>Sort By</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup floating>
          <CustomInput
            type='select'
            placeholder='Tag'
            name='tag'
            value={searchFilter.tag}
            onChange={onSearchFilterChange}
          >
            {c.inputsOptions.tagOptions.map((o, i) => (
              <option key={i} value={o.value}>
                {o.label}
              </option>
            ))}
          </CustomInput>
          <Label>Tag</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup floating>
          <CustomInput
            type='select'
            placeholder='Type'
            name='type'
            value={searchFilter.type}
            onChange={onSearchFilterChange}
          >
            {c.inputsOptions.typeOptions.map((o, i) => (
              <option key={i} value={o.value}>
                {o.label}
              </option>
            ))}
          </CustomInput>
          <Label>Type</Label>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup floating>
          <CustomInput
            type='select'
            placeholder='Reach Level'
            name='reactOutLevel'
            value={searchFilter.reactOutLevel}
            onChange={onSearchFilterChange}
          >
            <option value=''>Select</option>
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </CustomInput>
          <Label>Reach Level</Label>
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup>
          <InputGroup>
            <CustomInput
              name='handle'
              value={searchFilter.handle}
              onChange={onSearchFilterChange}
              placeholder='Search by Handle'
            />
            <Button color='primary' type='submit'>
              Search
            </Button>
          </InputGroup>
        </FormGroup>
      </Col>
    </Row>

    <div className='t-align-c'>
      <ButtonGroup size='sm'>
        <Button color='primary' onClick={toggleSettingsModal}>
          Settings
        </Button>
        <Button color='primary' onClick={onResetFiltersClick}>
          Reset Filters
        </Button>
      </ButtonGroup>
    </div>
  </Form>
);

export default Header;
