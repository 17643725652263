////////////////////////////////////////////////////////////////////////////////USER TYPES
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';
export const PUT_USER_FAILURE = 'PUT_USER_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
///////////////////////////////////////////////////////////////////////////////ADMIN TYPES
export const LOGIN_ADMIN_SUCCESS = 'LOGIN_ADMIN_SUCCESS';
export const LOGIN_ADMIN_FAILURE = 'LOGIN_ADMIN_FAILURE';
export const REGISTER_ADMIN_SUCCESS = 'REGISTER_ADMIN_SUCCESS';
export const REGISTER_ADMIN_FAILURE = 'REGISTER_ADMIN_FAILURE';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_ADMIN_FAILURE = 'GET_ADMIN_FAILURE';
export const LOGOUT_ADMIN_SUCCESS = 'LOGOUT_ADMIN_SUCCESS';
export const LOGOUT_ADMIN_FAILURE = 'LOGOUT_ADMIN_FAILURE';
export const PUT_ADMIN_SUCCESS = 'PUT_ADMIN_SUCCESS';
export const PUT_ADMIN_FAILURE = 'PUT_ADMIN_FAILURE';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE';
////////////////////////////////////////////////////////////////////////////PROSPECT TYPES
export const GET_PROSPECTS_SUCCESS = 'GET_PROSPECTS_SUCCESS';
export const GET_PROSPECTS_FAILURE = 'GET_PROSPECTS_FAILURE';
export const POST_PROSPECT_SUCCESS = 'POST_PROSPECT_SUCCESS';
export const POST_PROSPECT_FAILURE = 'POST_PROSPECT_FAILURE';
export const PUT_PROSPECT_SUCCESS = 'PUT_PROSPECT_SUCCESS';
export const PUT_PROSPECT_FAILURE = 'PUT_PROSPECT_FAILURE';
export const DELETE_PROSPECT_SUCCESS = 'DELETE_PROSPECT_SUCCESS';
export const DELETE_PROSPECT_FAILURE = 'DELETE_PROSPECT_FAILURE';
////////////////////////////////////////////////////////////////////////////////ITEM TYPES
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAILURE = 'GET_ITEMS_FAILURE';
export const POST_ITEM_SUCCESS = 'POST_ITEM_SUCCESS';
export const POST_ITEM_FAILURE = 'POST_ITEM_FAILURE';
export const PUT_ITEM_SUCCESS = 'PUT_ITEM_SUCCESS';
export const PUT_ITEM_FAILURE = 'PUT_ITEM_FAILURE';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';
export const RESET_BASKET_SUCCESS = 'RESET_BASKET_SUCCESS';
export const RESET_BASKET_FAILURE = 'RESET_BASKET_FAILURE';
///////////////////////////////////////////////////////////////////////////////EMAIL TYPES
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE';
export const PUSH_EMAIL_SUCCESS = 'PUSH_EMAIL_SUCCESS';
export const PUSH_EMAIL_FAILURE = 'PUSH_EMAIL_FAILURE';
///////////////////////////////////////////////////////////////////////////////ALERT TYPES
export const GET_MESSAGES = 'GET_MESSAGES';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
//////////////////////////////////////////////////////////////////////////////////////////
