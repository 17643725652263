///////////////////////////////////////////////////////////////////////////////////MODULES
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
////////////////////////////////////////////////////////////////////////////////APP ROUTER
import AppRouter from './routers/AppRouter';
/////////////////////////////////////////////////////////////////////////////////////STORE
import configureStore from './store/configureStore';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getUser } from './actions/user';
import { getAdmin } from './actions/admin';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from './utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

document.title = c.name;
document.getElementsByName('description')[0].content = c.slogan;
document.getElementsByName('viewport')[0].content = 'width=device-width, initial-scale=1, maximum-scale=1';

const store = configureStore();
const App = () => {
  useEffect(() => {
    const request = async () => {
      try {
        await store.dispatch(getUser());
        await store.dispatch(getAdmin());
      } catch (error) {
        console.log(error);
      }
    };

    request();
  }, []);

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

export default App;
