///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import {
  CustomInput,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  CloseButton,
  ListGroup,
  ListGroupItem,
} from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const Settings = ({ settingsModal, toggleSettingsModal, userInputs, setUserInputs, onSaveUserClick }) => {
  // State
  const [accountName, setAccountName] = useState('');
  const [nicheName, setNicheName] = useState('');

  // onChange functions
  const onAccountNameChange = e => setAccountName(e.target.value);
  const onNicheNameChange = e => setNicheName(e.target.value);

  // onClick functions
  const onAddAccountClick = _ => {
    try {
      setUserInputs(c => ({
        ...c,
        accounts: [...(userInputs?.accounts || undefined), { name: accountName, template: '' }],
      }));
    } catch (e) {
      console.error(e);
    }
  };

  const onAddNicheClick = _ => {
    try {
      setUserInputs(c => ({ ...c, niches: [...(userInputs?.niches || undefined), nicheName] }));
    } catch (e) {
      console.error(e);
    }
  };

  const onRemoveAccClick = i => {
    try {
      const accounts = userInputs?.accounts;
      accounts.splice(i, 1);

      setUserInputs(c => ({ ...c, accounts }));
    } catch (e) {
      console.error(e);
    }
  };

  const onRemoveNicheClick = i => {
    try {
      const niches = userInputs?.niches;
      niches.splice(i, 1);

      setUserInputs(c => ({ ...c, niches }));
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <Modal isOpen={settingsModal} toggle={toggleSettingsModal}>
      <ModalHeader toggle={toggleSettingsModal}>Settings</ModalHeader>
      <ModalBody>
        <h4 className='t-align-c'>Accounts</h4>

        <ListGroup>
          {userInputs?.accounts?.map((acc, i) => (
            <ListGroupItem key={i}>
              {acc.name}
              <CloseButton bsSize='sm' onClick={_ => onRemoveAccClick(i)} style={{ float: 'right' }} />
            </ListGroupItem>
          ))}
        </ListGroup>
        <br />

        <FormGroup>
          <Label>Account Name</Label>
          <InputGroup>
            <CustomInput required placeholder='Account Name' value={accountName} onChange={onAccountNameChange} />
            <Button color='primary' onClick={onAddAccountClick}>
              Add account
            </Button>
          </InputGroup>
        </FormGroup>

        <br />
        <h4 className='t-align-c'>Niches</h4>

        <ListGroup>
          {userInputs?.niches?.map((niche, i) => (
            <ListGroupItem key={i}>
              {niche}
              <CloseButton bsSize='sm' onClick={_ => onRemoveNicheClick(i)} style={{ float: 'right' }} />
            </ListGroupItem>
          ))}
        </ListGroup>
        <br />

        <FormGroup>
          <Label>Niche Name</Label>
          <InputGroup>
            <CustomInput required placeholder='Niche Name' value={nicheName} onChange={onNicheNameChange} />
            <Button color='primary' onClick={onAddNicheClick}>
              Add niche
            </Button>
          </InputGroup>
        </FormGroup>
      </ModalBody>
      <ModalFooter className='pos-b-s'>
        <Button color='primary' onClick={onSaveUserClick} className='w-100'>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Settings;
