///////////////////////////////////////////////////////////////////////////////////MODULES
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from "@ibiliaze/reactstrap";
import { msToTime, dtlToMs } from "@ibiliaze/time";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Tags from "../Prospect/Tags";
import Inputs from "../Prospect/Inputs";
//////////////////////////////////////////////////////////////////////////////////////////

const ProspectModal = ({
  userInputs,
  prospectModal,
  toggleProspectModal,
  prospectId,
  onTagClick,
  inputs,
  setInputs,
  onInputsChange,
  onSaveClick,
  onDeleteClick,
  onAutoFillClick,
}) => (
  <Modal isOpen={prospectModal} toggle={toggleProspectModal}>
    <ModalHeader toggle={toggleProspectModal}>Prospect Details</ModalHeader>
    <ModalBody>
      <Inputs
        prospectId={prospectId}
        userInputs={userInputs}
        inputs={inputs}
        setInputs={setInputs}
        onInputsChange={onInputsChange}
        onAutoFillClick={onAutoFillClick}
      />

      {!!prospectId && (
        <>
          <Tags onTagClick={onTagClick} />
          <div className="t-f-s-s t-gray">Last updated by {inputs.by}</div>
          <div className="t-f-s-s t-gray">Last updated at {msToTime(dtlToMs(inputs.updatedAt))}</div>
          <div className="t-f-s-s t-gray">Created at {msToTime(dtlToMs(inputs.createdAt))}</div>
        </>
      )}
    </ModalBody>
    <ModalFooter className="pos-b-s">
      <ButtonGroup className="w-100">
        {!!prospectId && (
          <Button color="danger" onClick={onDeleteClick}>
            Delete
          </Button>
        )}
        <Button color="primary" onClick={onSaveClick}>
          Save
        </Button>
      </ButtonGroup>
    </ModalFooter>
  </Modal>
);

export default ProspectModal;
