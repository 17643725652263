///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  FormGroup,
  CustomInput,
  Label,
} from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const TemplateModal = ({
  templateModal,
  toggleTemplateModal,
  onSaveUserClick,
  searchFilter,
  setUserInputs,
  userInputs,
}) => {
  // State
  const [template, setTemplate] = useState("");

  // onChange functions
  const onTemplateChange = (e) => {
    setTemplate(e.target.value);

    const account = searchFilter.account;
    setUserInputs((c) => ({
      ...c,
      accounts: [...c.accounts].map((acc) =>
        acc.name === account ? { ...acc, template: e.target.value } : acc
      ),
    }));
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      setTemplate(
        userInputs.accounts.find((acc) => acc.name === searchFilter.account)
          .template
      );
    } catch (e) {
      console.error(e);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  // JSX
  return (
    <Modal isOpen={templateModal} toggle={toggleTemplateModal}>
      <ModalHeader toggle={toggleTemplateModal}>DM Template</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Template</Label>
          <CustomInput
            type="textarea"
            rows="6"
            placeholder="DM Template"
            value={template}
            onChange={onTemplateChange}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter className="pos-b-s">
        <ButtonGroup className="w-100">
          <Button color="primary" onClick={onSaveUserClick}>
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default TemplateModal;
