///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CentredSpinner, CustomPagination } from "@ibiliaze/reactstrap";
import { FullPage, Section, copyToClipboard, Api } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postProspect, putProspect, deleteProspect, getProspects } from "../../actions/prospects";
import { putUser } from "../../actions/user";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../../utils/resolveEnv";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Header from "../Prospect/Header";
import Prospects from "../Prospect/Prospects";
import Settings from "../Prospect/Settings";
import ProspectModal from "../Prospect/ProspectModal";
import TemplateModal from "../Prospect/TemplateModal";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const prospectDefault = {
  link: "",
  handle: "",
  account: "",
  niche: "",
  type: "",
  email: "",
  tel: "",
  brand: "",
  description: "",
  tag: "Untagged",
  reactOutLevel: 0,
  by: "",
};

const filterDefault = {
  sortBy: "createdAt:desc",
  handle: "",
  type: "",
  tag: "",
  niche: "",
  account: "",
  reactOutLevel: "",
};

const ProspectsPage = ({
  prospects,
  user,
  postProspect,
  putProspect,
  deleteProspect,
  getProspects,
  putUser,
}) => {
  // State
  const [prospectModal, setProspectModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prospectId, setProspectId] = useState(null);
  const [search, setSearch] = useState(false);
  const [page, setPage] = useState(1);
  const [inputs, setInputs] = useState({ ...prospectDefault });
  const [searchFilter, setSearchFilter] = useState({ ...filterDefault });
  const [userInputs, setUserInputs] = useState({ accounts: [], niches: [] });

  // Toggle functions
  const toggleProspectModal = (_) => setProspectModal(!prospectModal);
  const toggleSettingsModal = (_) => setSettingsModal(!settingsModal);
  const toggleTemplateModal = (_) => setTemplateModal(!templateModal);

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));
  const onSearchFilterChange = (e) => setSearchFilter((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onAutoFillClick = async (link) => {
    try {
      const res = await api.post(`${resolveHost()}/api/prospect/autofill`, { link });
      const details = res.data.details;
      setInputs((c) => ({ ...c, ...details, account: searchFilter.account }));
    } catch (e) {
      console.error(e);
    }
  };

  const onPageClick = (e, index) => {
    e.preventDefault();
    index < 1 ? setPage(1) : setPage(index);
  };

  const onAddClick = (_) => {
    setInputs({ ...prospectDefault });
    setProspectId(null);
    toggleProspectModal();
  };

  const onOpenLinkClick = (prospect) => {
    try {
      let rendered = userInputs?.accounts?.find((acc) => acc.name === prospect.account).template;
      for (const key in prospect) {
        if (rendered.includes(`{{ ${key} }}`)) {
          rendered = rendered.replace(`{{ ${key} }}`, prospect[key]);
        } else if (rendered.includes(`{{ ${key} |`)) {
          let startIndex = rendered.indexOf("|") + 1; // Find the index of '|' and add 1 to exclude '|'
          let endIndex = rendered.indexOf("}}"); // Find the index of '}}'

          let extractedValue = rendered.substring(startIndex, endIndex).trim(); // Extract the substring between '|' and '}}', then trim any leading/trailing spaces

          rendered = rendered.replace(`{{ ${key} | ${extractedValue} }}`, prospect[key] || extractedValue);
        }
      }

      copyToClipboard(rendered);

      setTimeout(() => {
        window.open(prospect?.link);
      }, 1);
    } catch (e) {
      console.error(e);
    }
  };

  const onReachOutClick = async (prospect) => {
    try {
      await putProspect({
        ...prospect,
        userId: undefined,
        handle: undefined,
        prospectId: prospect?._id,
        reactOutLevel: prospect?.reactOutLevel > 2 ? 0 : prospect?.reactOutLevel + 1,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onResetFiltersClick = (_) => setSearchFilter({ ...filterDefault });

  const onProspectClick = async (prospect) => {
    try {
      setProspectId(prospect?._id);
      setInputs({ ...prospect });
      toggleProspectModal();
    } catch (e) {
      console.error(e);
    }
  };

  const onTagClick = async (e) => {
    try {
      await putProspect({ prospectId, tag: e.target.value });
    } catch (e) {
      console.error(e);
    }
  };

  const onSaveClick = async (_) => {
    try {
      const req = !!prospectId ? putProspect : postProspect;
      await req({
        ...inputs,
        by: localStorage.getItem("username"),
        userId: !!prospectId ? undefined : user?.user?._id,
        handle: !!prospectId ? undefined : inputs.handle,
        prospectId,
        account: inputs.account,
        niche: inputs.niche,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onSaveUserClick = async (_) => {
    try {
      delete user?.user?.email;
      delete user?.user?.password;
      await putUser({
        ...user?.user,
        accounts: userInputs.accounts,
        niches: userInputs.niches,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteClick = async (_) => {
    try {
      await deleteProspect(prospectId);
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      setUserInputs({ ...user?.user });
    } catch (e) {
      console.error(e);
    }
  }, [user]);

  useEffect(() => {
    setPage(1);
  }, [searchFilter.handle]);

  useEffect(() => {
    try {
      // Limit
      const limit = 50;
      const limitEndpoint = (l) => `&limit=${l}&skip=${l * (page - 1)}`;

      // Sort by
      const sortByEndpoint = `&sortBy=${searchFilter.sortBy}`;

      // Filters
      const handleEndpoint = `&handle=${searchFilter.handle}`;
      const accountEndpoint =
        searchFilter.account === ""
          ? ""
          : `&account=${searchFilter.account === "Unassigned" ? "" : searchFilter.account}`;
      const typeEndpoint = searchFilter.type === "" ? "" : `&type=${searchFilter.type}`;
      const tagEndpoint = searchFilter.tag === "" ? "" : `&tag=${searchFilter.tag}`;
      const nicheEndpoint = searchFilter.niche === "" ? "" : `&niche=${searchFilter.niche}`;
      const reachEndpoint =
        searchFilter.reactOutLevel === "" ? "" : `&reactOutLevel=${searchFilter.reactOutLevel}`;

      const req = async () => {
        setLoading(true);
        await getProspects(
          "?" +
            limitEndpoint(limit) +
            sortByEndpoint +
            handleEndpoint +
            typeEndpoint +
            tagEndpoint +
            nicheEndpoint +
            accountEndpoint +
            reachEndpoint
        );
        setLoading(false);
      };

      req();
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line
  }, [
    page,
    search,
    searchFilter.sortBy,
    searchFilter.tag,
    searchFilter.niche,
    searchFilter.account,
    searchFilter.type,
    searchFilter.reactOutLevel,
  ]);

  // JSX
  return (
    <FullPage>
      <Section className="custom-page below-header">
        <Header
          userInputs={userInputs}
          searchFilter={searchFilter}
          onSearchFilterChange={onSearchFilterChange}
          search={search}
          setSearch={setSearch}
          toggleSettingsModal={toggleSettingsModal}
          onResetFiltersClick={onResetFiltersClick}
        />

        <br />

        <CustomPagination page={page} onPageClick={onPageClick} />

        <Prospects
          userInputs={userInputs}
          setSearchFilter={setSearchFilter}
          page={page}
          prospects={prospects}
          onReachOutClick={onReachOutClick}
          onOpenLinkClick={onOpenLinkClick}
          onProspectClick={onProspectClick}
          onAddClick={onAddClick}
          toggleTemplateModal={toggleTemplateModal}
        />

        <TemplateModal
          templateModal={templateModal}
          toggleTemplateModal={toggleTemplateModal}
          onSaveUserClick={onSaveUserClick}
          searchFilter={searchFilter}
          userInputs={userInputs}
          setUserInputs={setUserInputs}
        />

        {loading ? (
          <CentredSpinner />
        ) : (
          <ProspectModal
            userInputs={userInputs}
            prospectModal={prospectModal}
            toggleProspectModal={toggleProspectModal}
            prospectId={prospectId}
            onTagClick={onTagClick}
            inputs={inputs}
            setInputs={setInputs}
            onInputsChange={onInputsChange}
            onSaveClick={onSaveClick}
            onDeleteClick={onDeleteClick}
            onAutoFillClick={onAutoFillClick}
          />
        )}

        <Settings
          settingsModal={settingsModal}
          toggleSettingsModal={toggleSettingsModal}
          userInputs={userInputs}
          setUserInputs={setUserInputs}
          onSaveUserClick={onSaveUserClick}
        />
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({ prospects: state.prospects, user: state.user });

export default connect(mapStateToProps, { postProspect, putProspect, deleteProspect, getProspects, putUser })(
  ProspectsPage
);
