///////////////////////////////////////////////////////////////////////////////////MODULES
import { Button, ButtonGroup } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const Tags = ({ onTagClick }) => (
  <div className='t-align-c'>
    <ButtonGroup>
      <Button color='danger' value='Unfit' onClick={onTagClick}>
        Unfit
      </Button>
      <Button color='primary' value='Potential' onClick={onTagClick}>
        Potential
      </Button>
      <Button color='secondary' value='Untagged' onClick={onTagClick}>
        Untagged
      </Button>
    </ButtonGroup>
    <br />
    <br />
  </div>
);

export default Tags;
