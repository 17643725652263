// eslint-disable-next-line no-unused-vars
const env = process.env;

export const config = ({
  // Have to set default values for repo-generator
  scroll = () => {},
  IOS17Filled = () => {},
}) => {
  /**
   * Before changing anything check the repo generator index.js and vanilla.json
   *
   * Icons needs to be denoted as functions. For example:
   * icon: _ => IOS17Filled({ name: 'money', size: '64' })
   * And when used in a component, they need to be called as functions: icon()
   */
  const configObj = {
    // GENERAL INFO
    name: 'Yecado',
    domain: 'yecado.com',
    slogan: 'Manage your prospects the easy way',
    tel: { tel1: '+447570695235' },
    email: { email1: 'yecadoldn@gmail.com' },
    mobileWidth: 767,

    // SOCIALS
    socials: {
      instagram: 'webifylake',
      facebook: 'webifylake',
      twitter: 'webifylake',
    },

    // NAV
    nav: {
      dark: true,
      blur: '1px',
      logoSrc: '/img/logo/logo.png',
      logoStyle: { height: '40px' },
      fadeOnPaths: ['/', '/auth', '/contact'],
    },

    // MAIN PIC
    mainPics: [
      {
        id: 'Home Page',
        imgPath: '/img/main/main.jpg',
        logo: '/img/logo/logo.png',
        height: '500px',
        mobileHeight: '300px',
        buttonText: 'Browse',
        onButtonClick: (navigate, input) => window.open(`/prospects`),
        inputPlaceHolder: 'What are you looking for?',
        formGroup: true,
      },
      {
        id: 'Parallax 1.0',
        parallax: true,
        parallaxPace: 0.35,
        noShadow: true,
        imgBottom: '0%',
        imgPath: '/img/main/main2.jpg',
        slogan: 'Track your progress easily.',
      },
      {
        id: 'Contact Page',
        imgPath: '/img/page/contact-page.jpg',
        height: '300px',
        mobileHeight: '300px',
      },
    ],

    // HEADERS
    headers: {
      infos2: {
        part1: { text: 'Our Most ' },
        part2: { text: 'Popular Catergories', gradient: true },
      },
      infos3: {
        part1: { text: 'Get to know us' },
      },
      infos4: {
        part1: { text: 'Grow Your Business ', gradient: true },
        part2: { text: 'With Your Customer Experience' },
      },
      infos5: {
        part1: { text: '100% ', gradient: true },
        part2: { text: 'Satisfied Users' },
      },
      album: {
        part1: { text: 'Our Work' },
      },
      reviews: {
        part1: { text: 'Reviews' },
      },
      services: {
        part1: { text: 'Services' },
      },
    },

    // TYPE EFFECTS
    textType: ['Prospecting', 'Automated Messages', 'DM Templates', 'Email Marketing'],

    // INFOS
    infos: {
      infos1: [
        {
          header: 'Manage Prospects',
          body: 'View all your prospects in a single portal.',
          img: '/img/infos/infos1-1.jpg',
          alt: 'infos1-1',
          icon: _ => IOS17Filled({ name: 'user', size: '50', color: 'ffffff' }),
          onClick: navigate => window.open('/auth'),
        },
        {
          header: 'Send Emails',
          body: 'Send automated emails to prospects.',
          img: '/img/infos/infos1-2.jpg',
          alt: 'infos1-2',
          icon: _ => IOS17Filled({ name: 'mail', size: '50', color: 'ffffff' }),
          onClick: navigate => window.open('/auth'),
        },
        {
          header: 'Track Progress',
          body: 'Follow up with prospects and build trust.',
          img: '/img/infos/infos1-3.jpg',
          alt: 'infos1-3',
          icon: _ => IOS17Filled({ name: 'positive-dynamic', size: '50', color: 'ffffff' }),
          onClick: navigate => window.open('/auth'),
        },
      ],
      infos2: [
        {
          header: 'Prospecting',
          onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
          icon: _ => IOS17Filled({ name: 'user', size: '50' }),
        },
        {
          header: 'DM Templates',
          onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
          icon: _ => IOS17Filled({ name: 'comments', size: '50' }),
        },
        {
          header: 'New Websites',
          onClick: navigate => window.open('https://www.webifylake.com'),
          icon: _ => IOS17Filled({ name: 'america', size: '50' }),
        },
        {
          header: 'Digital Marketing',
          onClick: navigate => window.open('https://www.webifylake.com'),
          icon: _ => IOS17Filled({ name: 'sale', size: '50' }),
        },
        {
          header: 'Copywriting',
          onClick: navigate => window.open('https://www.webifylake.com'),
          icon: _ => IOS17Filled({ name: 'pen', size: '50' }),
        },
      ],
      infos3: {
        title: 'What do we do?',
        text: 'We specialize in aiding digital marketers with prospecting by offering tools for visualizing potential clients and automating outreach. Our platform leverages data analytics and machine learning to provide insights into market segments, allowing for targeted strategies. With our automated messaging system, users can effortlessly send personalized messages, streamlining the prospecting process.',
        buttonText: 'Check Our Services',
        img1: '/img/infos/infos2-1.jpg',
        alt1: 'infos2-1',
        buttonOnClick: navigate => scroll(document.getElementById('services-section').offsetTop),
        flipperTitle: 'Webify Lake',
        flipperFlippedTitle: 'Webify Lake',
        flipperFlippedText:
          "You don't have to manage anything! You can choose to increase your server size if you have a large customer base.",
      },
      infos4: {
        header1: {
          part1: { text: 'About Your ' },
          part2: { text: 'Best Service', gradient: true },
        },
        header2: {
          part1: { text: 'How does the process work?' },
        },
        text1:
          "We host your web application in the cloud with maximum security. Your hosting service, domain registration, SSL certificate attachement will be done by us. You don't have to manage anything! You can choose to increase your server size if you have a large customer base.",
        text2:
          'Once you wish to begin hosting your website, one of our experienced engineers will contact you, they will work with you to help to personalise your website. Once complete we will host the website. Simple as that!',
        buttonText: 'Explore Options',
        buttonOnClick: navigate => scroll(document.getElementById('services-section')?.offsetTop),
        infos: [
          {
            header: 'Free Payment Systems',
            body: 'Add a FREE payment system addon to your site.',
          },
          {
            header: 'Admin Management',
            body: 'You can have the ability to create admin users that will manage your customer servicing needs.',
          },
          {
            header: 'Free Emails',
            body: '125,000 Emails per month are free of charge.',
          },
          {
            header: 'No refresh',
            body: 'Your web page will never reload under any circumstance: page browsing, submitting, adding, deleting & ammending.',
          },
          {
            header: 'Real-time',
            body: "Any data added by customers/users will automatically be viewed on your screen in real-time. You don't need to refresh your web site.",
          },
          {
            header: 'Mobile-ready',
            body: 'All sites are mobile-view-ready.',
          },
        ],
      },
      infos5: [
        {
          title: 'Prospects',
          text: '+',
          maxValue: 10000,
          incrementBy: 100,
          updateInterval: 10,
          color: 'rgb(112 144 183)',
          strokeWidth: 1.5,
        },
        {
          title: 'Users',
          text: '+',
          maxValue: 200,
          incrementBy: 2,
          updateInterval: 10,
          color: 'rgb(112 144 183)',
          strokeWidth: 1.5,
        },
        {
          title: 'Prospects',
          text: '+',
          maxValue: 30000,
          incrementBy: 300,
          updateInterval: 10,
          color: 'rgb(112 144 183)',
          strokeWidth: 1.5,
        },
      ],
      infos6: [
        {
          header: 'Create your account',
          body: 'Begin prospecting and gather information.',
          onClick: navigate => window.open('/auth'),
        },
        {
          header: 'Learn about Instagram prospecting',
          body: 'Understand the basics of freelancing with the most powerful social media platform.',
          onClick: navigate => window.open('https://mailchimp.com/resources/instagram-marketing-tips/'),
        },
        {
          header: 'Learn about email marketing',
          body: 'Understand the basics of email marketing.',
          onClick: navigate => window.open('https://blog.hubspot.com/marketing/make-emails-more-clickable-list'),
        },
      ],
      contact: {
        header: 'Contact Us',
        header2: 'Have a query for us?',
        flipperHeader: 'Contact Us',
        flipperHeader2: 'Different ways to reach us',
        flipperImg: '/img/contact.jpg',
      },
    },

    // CART
    cart: {
      cartName: 'Cart',
      cartSubmitButton: 'Contact Us',
      showPrice: true,
      showQuantity: true,
      showTotal: true,
      cartOnSubmit: async ({ e, inputs, cart, pushEmail }) => {
        e.preventDefault();

        try {
          await pushEmail({
            // Mail
            subject: 'New Order',
            to: configObj.email.email1,
            successReply: 'Order submitted to support',
            // HTML
            template: 'adminCart',
            args: { ...inputs, ...cart, currency: configObj.currency.currencySign },
          });

          await pushEmail({
            // Mail
            subject: 'Order submitted',
            to: inputs.email,
            successReply: 'Your order was submitted successfully',
            // HTML
            template: 'cart',
            args: { ...inputs, ...cart, currency: configObj.currency.currencySign },
          });
        } catch (e) {
          console.error(e);
        }
      },
    },

    // REVIEWS
    reviews: [
      {
        title: 'Webify Lake',
        body: 'Yecado made our prospecting a lot easier by allowing us to visualise our prospects and track our progress.',
      },
    ],

    // ALBUMS
    albums: {
      showAlbum: true,
      showInstagram: true,
      folder1: {
        md: 4,
        album: [
          '/img/album1/1.JPG',
          '/img/album1/2.JPG',
          '/img/album1/3.JPG',
          '/img/album1/4.JPG',
          '/img/album1/5.JPG',
          '/img/album1/6.JPG',
          '/img/album1/7.JPG',
          '/img/album1/8.JPG',
          '/img/album1/9.JPG',
          '/img/album1/10.JPG',
        ],
      },
    },

    // SERVICES
    services: {
      showPrice: true,
      showImage: false,
      multiclick: true,
      style: {},
      className: '',
      serviceGroups: [
        {
          serviceGroupName: 'Email Prospecting',
          serviceItems: [
            {
              serviceName: 'Email Templating',
              id: 'Email Templating',
              imageSrc: 'g',
              text: '',
              price: 0,
            },
          ],
        },
        {
          serviceGroupName: 'Instagram Prospecting',
          serviceItems: [
            {
              serviceName: 'Instagram DM Templating',
              id: 'Instagram DM Templating',
              imageSrc: 'g',
              text: '',
              price: 0,
            },
          ],
        },
      ],
    },

    // INPUT OPTIONS
    inputsOptions: {
      typeOptions: [
        { value: '', label: 'Select' },
        { value: 'Instagram', label: 'Instagram' },
        { value: 'Email', label: 'Email' },
        { value: 'Facebook', label: 'Facebook' },
        { value: 'TikTok', label: 'TikTok' },
        { value: 'X', label: 'X' },
        { value: 'LinkedIn', label: 'LinkedIn' },
      ],
      sortByOptions: [
        { value: '', label: 'Select' },
        { value: 'createdAt:asc', label: 'Oldest' },
        { value: 'createdAt:desc', label: 'Newest' },
        { value: 'updatedAt:asc', label: 'Updated - oldest' },
        { value: 'updatedAt:desc', label: 'Updated - soonest' },
      ],
      tagOptions: [
        { value: '', label: 'Select' },
        { value: 'Untagged', label: 'Untagged' },
        { value: 'Potential', label: 'Potential' },
        { value: 'Unfit', label: 'Unfit' },
      ],
    },

    extras: {
      showCartInHeader: false,
    },
  };

  return configObj;
};
