///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { CustomNav } from '@ibiliaze/reactstrap';
import { scroll } from '@ibiliaze/react-base';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { logout } from '../../actions/user';
import { logout as logoutAdmin } from '../../actions/admin';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Cart from '../Cart/Cart';
//////////////////////////////////////////////////////////////////////////////////////////

const Header = ({ cart, user, admin, logout, logoutAdmin }) => {
  // State
  const [cartModal, setCartModal] = useState(false);

  // Toggle functions
  const toggleCartModal = _ => setCartModal(!cartModal);

  // Navigate
  const navigate = useNavigate();

  // JSX
  return (
    <>
      <CustomNav
        {...c.nav}
        navbarBrand={c.name}
        navbarBrandHref='#'
        navbarBrandOnClick={_ => navigate('/')}
        navlinks={[
          {
            title: 'About',
            href: '#',
            onClick: _ => {
              navigate('/');
              setTimeout(() => {
                scroll(document.getElementById('about-section')?.offsetTop);
              }, 1);
            },
            hide: false,
          },
          {
            title: 'Services',
            href: '#',
            onClick: _ => {
              navigate('/');
              setTimeout(() => {
                scroll(document.getElementById('services-section')?.offsetTop);
              }, 1);
            },
            hide: false,
          },
          {
            title: 'Prospects',
            href: '#',
            onClick: _ => {
              navigate('/prospects');
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            },
            hide: !user?.isAuthenticated && !admin?.isAuthenticated,
          },
          {
            title: 'Contact',
            href: '#',
            onClick: _ => {
              navigate('/contact');
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            },
            hide: false,
          },
        ]}
        navlinksRight={[
          // guest links
          {
            title: `Cart ♥ ${cart.items.length}`,
            href: '#',
            onClick: toggleCartModal,
            hide: !c.extras.showCartInHeader,
            badge: true,
            badgeColour: 'danger',
          },
          {
            title: 'Login',
            href: '#',
            onClick: _ => {
              navigate('/auth');
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            },
            hide: user?.isAuthenticated || admin?.isAuthenticated,
          },

          // auth links
          {
            title:
              (user && user?.user ? `${user?.user?.name}@${c.name}` : '') ||
              (admin && admin?.admin ? `${admin?.admin?.name}@${c.name}` : ''),
            href: '#',
            hide: !user?.isAuthenticated && !admin?.isAuthenticated,
            onClick: _ => {
              if (user?.isAuthenticated) {
                navigate('/prospects');
              }
              if (admin?.isAuthenticated) {
                navigate('/admin');
              }
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            },
          },
          {
            title: 'Logout',
            href: '#',
            hide: !user?.isAuthenticated && !admin?.isAuthenticated,
            onClick: _ => {
              if (user?.isAuthenticated) {
                logout();
              }
              if (admin?.isAuthenticated) {
                logoutAdmin();
              }
            },
          },
        ]}
      />

      <Cart modal={cartModal} toggle={toggleCartModal} />
    </>
  );
};

const mapStateToProps = state => ({ cart: state.cart, user: state.user, admin: state.admin });

export default connect(mapStateToProps, { logout, logoutAdmin })(Header);
