///////////////////////////////////////////////////////////////////////////////////MODULES
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
//////////////////////////////////////////////////////////////////////////////////REDUCERS
import user from '../reducers/user';
import admin from '../reducers/admin';
import prospects from '../reducers/prospects';
import alert from '../reducers/alert';
import cart from '../reducers/cart';
//////////////////////////////////////////////////////////////////////////////////////////

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const configureStore = () => {
  const store = createStore(
    combineReducers({ user, admin, prospects, alert, cart }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};

export default configureStore;
