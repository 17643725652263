///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from "react";
import { CustomButton, CustomInput, FormGroup, InputGroup, Label, Spinner } from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const Inputs = ({ prospectId, userInputs, inputs, setInputs, onInputsChange, onAutoFillClick: autofill }) => {
  // State
  const [loading, setLoading] = useState(false);

  // onClick functions
  const onClearClick = (_) => {
    try {
      setInputs({ ...inputs, link: "" });
    } catch (e) {
      console.error(e);
    }
  };

  const onAutoFillClick = async (link) => {
    try {
      setLoading(true);
      await autofill(link);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  // JSX
  return (
    <>
      <FormGroup>
        <Label>Link *</Label>
        <InputGroup>
          <CustomInput
            required
            placeholder="instagram.com/myaccount/"
            type="string"
            name="link"
            value={inputs.link}
            onChange={onInputsChange}
          />
          <CustomButton color="primary" onClick={onClearClick}>
            Clear
          </CustomButton>
          <CustomButton
            disabled={loading ? true : false}
            color="primary"
            black
            onClick={(_) => onAutoFillClick(inputs.link)}
          >
            {loading ? <Spinner type="grow" size="sm" /> : "Autofill"}
          </CustomButton>
        </InputGroup>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          disabled={!!prospectId ? true : false}
          required
          placeholder="Handle *"
          type="string"
          name="handle"
          value={inputs.handle}
          onChange={onInputsChange}
        />
        <Label>Handle *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Owner Name"
          type="string"
          name="name"
          value={inputs.name}
          onChange={onInputsChange}
        />
        <Label>Owner Name</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Brand Name"
          type="string"
          name="brand"
          value={inputs.brand}
          onChange={onInputsChange}
        />
        <Label>Brand Name</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          type="select"
          required
          placeholder="Account *"
          name="account"
          value={inputs.account}
          onChange={onInputsChange}
        >
          <option value="">Select</option>
          {userInputs?.accounts?.map((acc, i) => (
            <option key={i}>{acc.name}</option>
          ))}
        </CustomInput>
        <Label>Account *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          type="select"
          placeholder="Niche"
          name="niche"
          value={inputs.niche}
          onChange={onInputsChange}
        >
          <option value="">Select</option>
          {userInputs?.niches?.map((a, i) => (
            <option key={i}>{a}</option>
          ))}
        </CustomInput>
        <Label>Niche</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Type *"
          type="select"
          name="type"
          value={inputs.type}
          onChange={onInputsChange}
        >
          <option value="">Select</option>
          <option>Instagram</option>
          <option>Email</option>
          <option>Facebook</option>
          <option>TikTok</option>
          <option>X</option>
          <option>LinkedIn</option>
        </CustomInput>
        <Label>Type *</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Email"
          type="string"
          name="email"
          value={inputs.email}
          onChange={onInputsChange}
        />
        <Label>Email</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Phone"
          type="string"
          name="tel"
          value={inputs.tel}
          onChange={onInputsChange}
        />
        <Label>Phone</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          required
          placeholder="Description"
          type="textarea"
          name="description"
          value={inputs.description}
          onChange={onInputsChange}
        />
        <Label>Description</Label>
      </FormGroup>
    </>
  );
};

export default Inputs;
